<template>
  <b-container class="view-container" fluid>
    <div class="justify-content-center pb-4">
      <pdf-viewer :getDocumentHandler="getDocumentHandler" />
    </div>
    <div>
      <b-button variant="primary" @click="downloadPdf()">
        Baixar declaração em PDF
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  name: "cooperative-declaration-tab",
  components: {
    PdfViewer,
  },
  methods: {
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      return result;
    },
    downloadPdf() {
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "declaração-cooperado.pdf",
        this.downloadedFile
      );
    },
    async downloadFile() {
      try {
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          "DOCTOR_DECLARATION",
          new Date().getFullYear()
        );

        this.fileUrl = response.fileUrl;
        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container.container-fluid {
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  height: 60vh;
}
@media only screen and (min-width: 600px) {
  .bt-download-dec {
    margin-left: 19rem;
  }
}
</style>
